@font-face {
    font-family: 'Gotham';
    src: url('../font-gotham/GothamBold.eot');
    src: url('../font-gotham/GothamBold.eot?#iefix') format('embedded-opentype'),
        url('../font-gotham/GothamBold.woff2') format('woff2'),
        url('../font-gotham/GothamBold.woff') format('woff'),
        url('../font-gotham/GothamBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../font-gotham/GothamMedium.eot');
    src: url('../font-gotham/GothamMedium.eot?#iefix') format('embedded-opentype'),
        url('../font-gotham/GothamMedium.woff2') format('woff2'),
        url('../font-gotham/GothamMedium.woff') format('woff'),
        url('../font-gotham/GothamMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

