#id_team-tournaments {
    display: none;
}
#team-tournaments {
    // margin-top: $grid-gutter-width/2;
    font-size: $font-size-small;
    .col {
        text-align: center;
    }
    .sport-item {
        // margin-top: $grid-gutter-width/2;
        // margin-bottom: $grid-gutter-width/2;
        &:nth-child(odd) {
            background: #f5f5f5;
        }
        @include clearfix();
        padding: 5px 0;
    }
    .tournament-item {
        margin: 5px 0;
        padding: $grid-gutter-width/3 5px;
        text-align: center;
        text-transform: uppercase;
        font-size: $font-size-small;
        color: white;
        background: $gray-lighter;
        @media(min-width: $screen-xs-min) {
            font-size: $font-size-base;
        }
    }
    .tournament-item.allowed {
        background: $brand-primary;
        cursor: pointer;
    }
    .tournament-item.selected {
        background: $brand-success;
        &:after {
            content: " \f00c";
            font: normal normal normal 14px/1 FontAwesome;
            font-size: $font-size-large;
            line-height: $font-size-base;
        }
    }
    .tournament-check {
        font-size: $font-size-large;
        vertical-align: middle;
        color: #ddd;
        &.allowed {
            @include opacity(1);
            color: $text-color;
            cursor: pointer;
        }
        &.selected {
            // color: darken($brand-success, 20%);
        }
        &.fa-square-o {
            margin-right: 2px; // Fix width diference from fa-check-square-o
        }
    }
    .tournament-name {
        &.col {
            text-align: left;
        }
        @media(min-width: $screen-sm-min) {
            display: block;
            // padding-top: $grid-gutter-width/3 + 5px;
            span {
                float: right;
            }
        }
    }
    .caption {
        margin-top: $grid-gutter-width/2;
        text-align: right;
        i {
            font-size: $font-size-large;
            vertical-align: middle;
        }
    }
}
