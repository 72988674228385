@import "bootstrap";
@import "../bootstrap-datepicker/css/bootstrap-datepicker3.min";

// Only Busup box
@import "../font-gotham/stylesheet";


body {
    overflow-y: scroll;
    background: lighten($gray-base, 10%);
}

.cookies-bar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: $grid-gutter-width/2;
    background: white;
    background: rgba(white, 0.95);
    border-bottom: solid 1px $primary-color;
    color: $text-color;
    text-align: center;
    @include box-shadow(0 0 4px $gray-light);
}

.page-wrapper {
    background: white;
}

.container {
    max-width: 970px;
}
.main-container {
    min-height: 220px;
}

.col-sm-clear {
    clear: both;
}

#menu-bar {
    .navbar {
        border: none;
        background: $gray-darker;
        border-bottom: solid 1px $alter-1-color;
        a {
            color: white;
            text-transform: uppercase;
            background: transparent;
            &:hover {
                color: $primary-color;
            }
        }
        .active a {
            color: $brand-primary;
            background: transparent;
        }
    }
}

#header {
    .banner {
        display: none;
    }
    @media (min-width: $screen-xs-min) {
        .banner {
            display: block;
        }
        .banner-mobile {
            display: none;
        }
    }
}
@media (max-width: $screen-xs-max) {
    #header .container,
    #menu-bar {
        padding: 0;
    }
}

#footer {
    margin-top: 60px;
    padding: 15px;
    font-size: 0.8em;
    color: $gray-light;
    text-align: center;
    background: lighten($gray-base, 10%);
}

.form-group.required label:after {
    content: ' *';
}
.form-group.radioselect ul {
    list-style: none;
}
.form-group.radioselect ul label {
    font-weight: normal;
}
.form-group.radioselect ul label:after {
    content: normal;
}
.form-buttons {
    margin-top: 30px;
}
.form-buttons .btn.pull-right {
    margin-left: 15px;
}
.input-group.date .input-group-addon {
    cursor: pointer;
}

#language-switcher {
    text-transform: uppercase;
    #menu-bar & a {
        color: $alter-1-color;
        &:hover {
            color: $primary-color;
        }
    }
    .current {
        font-weight: bold;
    }
    @media (min-width: $screen-sm-min) {
        margin-right: 0;
    }
}

.home-register-buttons {
    margin-top: $grid-gutter-width;
    .row {
        margin-bottom: $grid-gutter-width;
    }
    img {
        margin-bottom: $grid-gutter-width;
        margin-left: auto;
        margin-right: auto;
    }
    .price {
        strong {
            @media (min-width: $screen-xs-min) {
                font-size: 26px;
            }
        }
    }
    .fase-prices {
        h4 {
            margin-top: 0;
            font-weight: bold;
            text-transform: uppercase;
        }
        padding: $grid-gutter-width/2;
        background: $brand-info;
        background-position: center center;
        background-size: cover;
        color: white;
        text-align: center;
    }
    @media (min-width: $screen-sm-min) {
        img {
            margin-bottom: 0;
        }
        .price {
            margin-top: 20px;
        }
    }
    @media (min-width: $screen-md-min) {
        .price {
            margin-top: 30px;
        }
    }
}

.bus-info {
    display: block;
    margin-top: $grid-gutter-width*2;
    padding: $grid-gutter-width/2;
    background: $brand-info;
    background-position: center center;
    background-size: cover;
    color: white;
    text-align: center;
    font-family: "Gotham", $font-family-base;
    &:hover,
    &:focus {
        color: white;
        text-decoration: none;
        color: $primary-color;
    }
    .h4 {
        margin-top: 0;
        margin-bottom: ($line-height-computed / 2);
        font-weight: bold;
    }
    @media (min-width: $screen-sm-min) {
        margin-top: $grid-gutter-width;
        padding: $grid-gutter-width;
        > div {
            display: inline-block;
            margin: 0 ($line-height-computed / 2);
            vertical-align: middle;
        }
        .h4 {
            margin-bottom: 0;
        }
    }
}

.signup-form-steps-bar {
    margin-top: $grid-gutter-width/3;
    margin-bottom: $grid-gutter-width;
    .btn-primary,
    .btn-primary:disabled {
        background: $primary-color;
        background-color: $primary-color;
        @include opacity(1);
        font-weight: bold;
    }
}
@media (max-width: $screen-sm-max) {
    .signup-form-steps-bar .btn-group-justified {
        display: block;
    }
    .signup-form-steps-bar .btn-group-justified .btn-group {
        display: block;
        width: 100%;
        margin-left: 0;
        margin: 5px 0;
    }
    .signup-form-steps-bar .btn-group-justified .btn {
        display: block;
        float: none;
    }
}

.signup-form-steps-bar-2 {
    position: relative;

margin-top: 30px; ////////////////////////////////   Remove-me

    &:before {
        content: '';
        position: absolute;
        width: 5px;
        top: 10px;
        left: 18px;
        bottom: 10px;
        background: #ddd;
    }
    .step {
        position: relative;
        margin: $grid-gutter-width/2 0;
    }
    .step-button {
        display: inline-block;
        width: 40px;
        height: 40px;
        padding: 5px;
        border-radius: 50%;
        background: #ddd;
    }
    .step-name {
        display: inline-block;
        text-transform: uppercase;
        margin-left: $grid-gutter-width/2;
    }
    button {
        width: 30px;
        height: 30px;
        padding-left: 0;
        padding-right: 0;
        border-radius: 50%;
    }
    .step-current {
        .step-name {
            font-weight: bold;
        }
    }
    @media(min-width: $screen-sm-min) {
        display: table;
        width: 100%;
        table-layout: fixed;
        border-collapse: separate;
        &:before {
            display: none;
        }
        .step {
            float: none;
            display: table-cell;
            width: 1%;
            &:before,
            &:after {
                content: '';
                position: absolute;
                z-index: 0;
                height: 5px;
                top: 17px;
                background: #ddd;
            }
            &:before {
                left: 0px;
                right: 50%;
            }
            &:after {
                left: 50%;
                right: 0px;
            }
            &:first-child:before {
                display: none;
            }
            &:last-child:after {
                display: none;
            }
        }
        .step-button {
            position: relative;
            z-index: 1;
            display: block;
            margin: 0 auto;
        }
        .step-name {
            display: block;
            text-align: center;
            margin: $grid-gutter-width/2 0 0 0;
        }
    }
}

.help-block {
    font-size: 0.8em;
    + .help-block {
        margin-top: -5px;
    }
}

.page-header {
    border-color: $alter-1-color;
}


/* ==========================================================================
   Wizard
   ========================================================================== */

.signup-form .join-team-note {
    margin-bottom: $line-height-computed;
    color: $brand-primary;
    font-weight: bold;
    font-size: $font-size-large;
    text-align: center;
}

/*
 * Join Team
 */

.signup-form-join_team {
    .form-group {
        @media (min-width: $screen-md-min) {
            width: 25%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

/*
 * Team
 */

.signup-form-team {
    @import "team_form";
}

/*
 * Particpants
 */

.signup-form-participant,
.signup-form-participants {
    .participant-item {
        .field-region,
        .field-city {
            display: none;
        }
    }
}

/*
 * Extras
 */

.signup-form-extra {
    #id_extra-extra_zero_night {
        li {
            margin-top: $grid-gutter-width;
            margin-bottom: $grid-gutter-width;
            font-size: $font-size-large;
        }
        label {
            margin: 0;
        }
    }
}

/*
 * Package
 */

.signup-form-package #id_package-package .separator div {
    height: 33px;
    width: 100%;
    margin: $grid-gutter-width/2 0;
    background-position: center center;;
    background-repeat: no-repeat;
    background-size: cover;
}
.signup-form-package #id_package-package label {
    position: relative;
    margin: 0 0 15px 0;
    cursor: pointer;
}
.signup-form-package #id_package-package label img {
    border: solid 5px transparent;
}
.signup-form-package #id_package-package input {
    position: absolute;
    bottom: 15px;
    left: 5px;
}
.signup-form-package #id_package-package input[type="radio"]:checked + img {
    border-color: $primary-color;
}
.signup-form-package #id_package-package .package-name {
    margin: 5px 0 0 30px;
}
.signup-form-package #id_package-package .package-price {
    margin: 0 0 0 30px;
}

/*
 * Kids
 */

.signup-form-kids .buttons {
    margin: 15px 0 60px 0;
}
.signup-form-kids .kid-package-col {
    margin: 20px 0 15px 0;
}
.signup-form-kids .kid-package-col img {
    margin-bottom: 5px;
}
.signup-form-kids .red {
    background: #fdd;
}

/*
 * Store
 */

.signup-form-store .form-group,
.signup-form-store .form-group .help-block {
    margin-bottom: 0;
}
.signup-form-store {
    h3 {
        margin: 0;
        font-size: $font-size-large;
        font-weight: bold;
    }
    .product-item {
        margin-bottom: $grid-gutter-width*2;
        @media (min-width: $screen-md-min) {
            margin-bottom: $grid-gutter-width;
        }
    }
    .item-picture {
        margin-bottom: $grid-gutter-width/2;;
        @media (min-width: $screen-md-min) {
            margin-bottom: 0;
        }
    }
    .item-qty {
        margin: $grid-gutter-width/2 0;
        .form-control {
            display: inline;
            width: 70px;
        }
    }
}

/*
 * Recap
 */

.signup-form-last_step {
    #id_last_step-discount_code {
        display: inline-block;
        width: 200px;
        margin-right: 5px;
    }
    .survey {
        margin-top: $grid-gutter-width;
    }
    .participation-waiver {
        margin-top: $grid-gutter-width;
        font-weight: bold;
    }
}

#signup-recap {
    .signup-recap-top-text {
        margin-bottom: $grid-gutter-width/2;
    }
}
